.page._home .page__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 17px;
}

.page._home .page__select-city {
    margin-bottom: 12px;
}

.page._home .page__select-city a {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #7F7E82 !important;
    text-decoration: none;
}

.page._home .page__loader {
    position: fixed;
    right: 10px;
    bottom: 10px;
}

.page._home .page__title {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    color: #000000;
}

.page._home .page__search {
    margin-bottom: 21px;
}

.page._home .page__category {
    margin-bottom: 20px;
}

.page._home .page__filter {
    margin-bottom: 20px;
    margin-left: -20px;
    margin-right: -20px;
}

.category._default {

}

.category._default .category__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.category._default .category__link {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #7F7E82 !important;
    text-decoration: none;
    white-space: nowrap;
}

.category._default .category__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #000000;    
}

#allrecords .category._default .category__title a {
    text-decoration: none;
    color: #000000 !important;  
}

.category._default .category__list {
    display: flex;
    gap: 11px;
    overflow: auto;
    margin: 0 -20px;
}

.category._default .category__list._vertical {
    overflow: visible;
    margin-left: 0;
    margin-right: 0;
    flex-wrap: wrap;
}

.category._default .category__list._vertical:before,
.category._default .category__list._vertical:after {
    display: none;
}

.category._default .category__list:before,
.category._default .category__list:after {
    content: '';
    width: 9px;
    min-width: 9px;
    height: 20px;
}

.category._default .category__list::-webkit-scrollbar {
    display: none;
}


.filter._category {
    display: flex;
    align-items: center;
    gap: 8px;
}

.filter._category:before {
    content: '';
    min-width: 12px;
    width: 12px;
    height: 20px;
}

.filter._category .filter__toggle {
    width: 36px;
    min-width: 36px;
    height: 36px;
    background: #F1F2F3;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
}

.filter._category .filter__list {
    display: flex;
    gap: 8px;
    overflow: auto;
}

.filter._category .filter__list:after {
    content: '';
    width: 12px;
    min-width: 12px;
    height: 20px;
}

.filter._category .filter__list::-webkit-scrollbar {
    display: none;
}

.filter-list._desktop {
    display: none;
}

@media screen and (min-width: 1023px) {

    .page._home .page__hero {
        display: flex;
        justify-content: space-between;
    }

    .page._home .page__filter {
        margin-left: 0;
        margin-right: 0;
    }

    .page._home .page__search {
        width: 282px;
    }

    .filter._category .filter__toggle {
        display: inline-flex;
        vertical-align: middle;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .filter._category {
        display: inline-block;
    }

    .filter._category .filter__list {
        display: inline;
        overflow: visible;
    }

    .filter._category .filter__list-item {
        display: inline-flex;
        vertical-align: middle;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .page._home .page__filter {
        position: relative;
        z-index: 20;
    }

    .filter-list._desktop {
        padding: 52px;
        position: absolute;
        left: 0;
        width: 100%;
        max-width: 452px;
        top: 44px;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
        z-index: 30;
    }

    .filter-list._desktop .filter-list__title {
        margin-bottom: 24px;
        font-family: 'Styrene B LC';
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 133.333% */
    }

    .filter-list._desktop .filter-list__close {
        position: absolute;
        right: 52px;
        top: 30px;
    }
    
    .filter-list._desktop .filter-list__wrap {
        overflow: auto;
        max-height: 684px;
        padding-right: 16px;
    }

    .filter-list._desktop._show {
        display: block;
    }
    
}