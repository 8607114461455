.modal._default._sales {
    z-index: 800;
}

.modal._default._sales .modal__wrap {
    padding-bottom: 74px;
}

.sales__title {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: 0.37px;
}

.sales__list {
    margin: -6px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.sales__list-item {
    width: 50%;
    padding: 6px;
}

.sales .post._sale {
    width: 100%;
}

@media screen and (max-width: 1023px) {

    .sales__list-item:nth-child(6n + 1) .post._sale{
        height: 252px;
    }

    .sales__list-item:nth-child(6n + 2) .post._sale{
        height: 120px;
    }

    .sales__list-item:nth-child(6n + 3) .post._sale{
        height: 120px;
    }

    .sales__list-item:nth-child(6n + 4) .post._sale{
        height: 252px;
    }

    .sales__list-item:nth-child(6n + 4) {
        margin-top: -132px;
    }

    .sales__list-item:nth-child(6n + 5) .post._sale{
        height: 120px;
    }

    .sales__list-item:nth-child(6n + 6) .post._sale{
        height: 120px;
    }

}

@media screen and (min-width: 1023px) {

    .sales__title {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 30px;
        padding-left: 32px;
    }

    .sales__list {
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
    }

    .sales__list-item {
        width: 282px;
        padding: 0;
        margin: 0;
    }
    
}