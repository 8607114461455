.checkbox._city .checkbox__input {
    opacity: 0;
    position: absolute;
    overflow: hidden;
    width: 0;
    height: 0;
}

.checkbox._city .checkbox__wrap {
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: 0.37px;    
    color: #000000;
    display: block;
}

.checkbox._city .checkbox__input:checked + .checkbox__wrap {
    background: url('https://cdn.podeli.ru/web-catalog/assets/img/icon-check-city.svg') no-repeat right center;
}