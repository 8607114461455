.navbar._default {
    position: fixed;
    background: #F1F2F3;
    border-radius: 12px 12px 0px 0px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 7px 24px;
}

.navbar._default .navbar__menu {
    display: flex;
    gap: 12px;
    justify-content: space-between;
}

.navbar._default .navbar__menu-icon {
    width: 24px;
    height: 24px;
    margin: 0 auto 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: .5;
}

.navbar._default .navbar__menu-link {
    text-decoration: none;
    text-align: center;
    display: block;
}

.navbar._default .navbar__menu-title {
    color: #A7ABAC !important;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    display: block;
}

.navbar._default .navbar__menu-link._active .navbar__menu-icon {
    opacity: 1;
}

.navbar._default .navbar__menu-link._active .navbar__menu-title {
    color: #505759 !important;
}