.checkbox._category .checkbox__input {
    opacity: 0;
    position: absolute;
    overflow: hidden;
    width: 0;
    height: 0;
}

.checkbox._category .checkbox__wrap {
    display: inline-flex;
    height: 36px;
    background: #F1F2F3;
    border-radius: 6px;
    padding: 8px 12px;
    gap: 8px;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
}

.checkbox._category .checkbox__icon {
    opacity: .3;
    width: 18px;
    min-width: 18px;
}

.checkbox._category .checkbox__title {
    opacity: .3;
    margin-top: 3px;
}

.checkbox._category .checkbox__input:checked + .checkbox__wrap .checkbox__icon {
    opacity: 1;
}

.checkbox._category .checkbox__input:checked + .checkbox__wrap .checkbox__title {
    opacity: 1;
}