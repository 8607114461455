.post._sale {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 254px;
    height: 152px;
    position: relative;
    color: #fff;
    text-decoration: none;
    padding: 16px;
    gap: 8px;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    border-radius: 8px;
}

.post._sale .post__logo {
    position: absolute;
    top: 8px;
    left: 8px;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.post._sale .post__logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.post._sale .post__price {
    position: absolute;
    bottom: 8px;
    left: 8px;
    padding: 4px 8px;
    height: 24px;
    border-radius: 8px;
    background: #000000;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
}

.post._sale .post__title {
    max-width: 137px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.post._sale .post__description {
    max-width: 155px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;   
}

.post._sale .post__shops {
    display: none;
}

@media screen and (min-width: 1023px) {

    .post._sale {
        width: 282px;
        height: 168px;
    }

    .post._sale .post__shops {
        position: absolute;
        display: inline-flex;
        padding: 4px 8px;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 8px;
        border-radius: 4px;
        background: #fff;
        right: 37px;
        top: 8px;
    }

}