.results._search {
    display: flex;
    flex-direction: column;
    gap: 31px;
    padding-left: 20px;
    padding-right: 20px;
}

.results._search .results._category {
    width: 100%;
}

.results._search .category._shops {
    
}

.results._search .category._products .category__list._vertical {
    gap: 0;
    margin-left: -6px;
    margin-right: -6px;
}

.results._search .category._products .category__list._vertical .category__list-item {
    width: 50%;
    margin-bottom: 21px;
    padding-left: 6px;
    padding-right: 6px;
}

.results._search .category._shops .category__list {
    gap: 16px;
}

.results._search .category._shops .category__list:before,
.results._search .category._shops .category__list:after {
    width: 4px;
    min-width: 4px;
}

.post._short-shop {
    text-decoration: none;
    color: #000000;
    text-align: center;
    display: block;
}

.post._short-shop .post__preview {
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; 
    margin-bottom: 8px;  
}

.post._short-shop .post__title {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.post._short-product {
    display: flex;
    flex-direction: column;
    text-decoration: none;
}

.post._short-product .post__preview {
    display: block;
    height: 162px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 8px;
    margin-bottom: 12px;
}

.post._short-product .post__shop {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 4px;
}

.post._short-product .post__category {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #7F7E82;
}

.post._short-product .post__title {
    margin-bottom: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}

.post._short-product .post__price {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;  
    margin-bottom: 4px;  
}

.post._short-product .post__shop {
    
}

.podeli {
    padding: 4px 8px 4px 30px;
    min-height: 24px;
    background: #EF3124 url('https://cdn.podeli.ru/web-catalog/assets/img/icon-logo-podeli.svg') no-repeat 8px center;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
}


.not-found {
    margin-bottom: 24px;
}

.not-found__title {
    font-family: Styrene B LC;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 133.333% */
    margin-bottom: 8px;
}

.not-found__description {
    color: #7F7E82;
    font-family: Styrene B LC;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

@media screen and (min-width: 1023px) {

    .results._search .category._products .category__list._vertical .category__list-item {
        width: 166px;
        padding: 0;
        margin-bottom: 0;
    }

    .results._search .category._products .category__list._vertical {
        margin: 0;
        gap: 24px;
    }
    
}