@font-face {
    font-family: 'Styrene B LC';
    src: url('https://cdn.podeli.ru/web-catalog/assets/fonts/StyreneBLC-Regular.woff2') format('woff2'),
        url('https://cdn.podeli.ru/web-catalog/assets/fonts/StyreneBLC-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Styrene A LC';
    src: url('https://cdn.podeli.ru/web-catalog/assets/fonts/StyreneALC-Regular.woff2') format('woff2'),
        url('https://cdn.podeli.ru/web-catalog/assets/fonts/StyreneALC-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Styrene A LC';
    src: url('https://cdn.podeli.ru/web-catalog/assets/fonts/StyreneALC-Medium.woff2') format('woff2'),
        url('https://cdn.podeli.ru/web-catalog/assets/fonts/StyreneALC-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Styrene B LC';
    src: url('https://cdn.podeli.ru/web-catalog/assets/fonts/StyreneBLC-Medium.woff2') format('woff2'),
        url('https://cdn.podeli.ru/web-catalog/assets/fonts/StyreneBLC-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Styrene B LC';
    src: url('https://cdn.podeli.ru/web-catalog/assets/fonts/StyreneBLC-Bold.woff2') format('woff2'),
        url('https://cdn.podeli.ru/web-catalog/assets/fonts/StyreneBLC-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Styrene A LC';
    src: url('https://cdn.podeli.ru/web-catalog/assets/fonts/StyreneALC-Bold.woff2') format('woff2'),
        url('https://cdn.podeli.ru/web-catalog/assets/fonts/StyreneALC-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

