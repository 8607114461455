.page._inner._shop {
    padding-top: 0;
}

.page._inner._shop .page__wrap {
    min-height: 100vh;
    padding-bottom: 180px;
    padding-top: 246px;
}

.page._inner._shop .page__bg {
    display: none;
}

.page._inner._shop .page__header {
    position: fixed;
    top: 20px;
    left: 16px;
    z-index: 100;
}

.page._inner._shop .page__footer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    z-index: 150;
}

.shop._single .shop__preview {
    height: 262px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 40;
    background-color: rgba(0,0,0,.3);
}

.shop._single .shop__logo {
    width: 68px;
    height: 68px;
    background: #F1F2F3;
    border-radius: 50%;
    position: absolute;
    bottom: 52px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.shop._single .shop__logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.shop._single .shop__content {
    background: #FFFFFF;
    border-radius: 16px 16px 0 0;
    margin-top: -8px;
    margin-left: -20px;
    margin-right: -20px;
    padding: 24px 20px 0;
    position: relative;
    z-index: 50;
}

.shop._single .shop__sales {
    margin-bottom: 25px;
}

.shop._single .shop__sales-buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 12px;
}

.shop._single .shop__sales-description {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #7F7E82;
}

.shop._single .shop__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 8px;
}

.shop._single .shop__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}

.shop._single .shop__description p {
    /* margin-bottom: 20px; */
}

.shop._single .shop__alpha {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.5);
    z-index: 1030;
    display: flex;
    align-items: flex-end;
    top: 0;
    transition: .3s;
    opacity: 0;
    pointer-events: none;
}

.shop._single .shop__alpha._active {
    opacity: 1;
    pointer-events: all;
}

.alpha {
    width: 100%;
    background: #24252E;
    border-radius: 8px 8px 0px 0px;
    padding: 60px 20px 20px;
    position: relative;
    color: #fff;
}

.alpha .alpha__img {
    margin-bottom: 24px;
}

.alpha .alpha__img img {
    border-radius: 16px;
}

.alpha .alpha__title {
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    color: #FFFFFF;
    margin-bottom: 8px;
}

.alpha .alpha__lead {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 24px;
}

.alpha .alpha__buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.shop._single .shop__alpha-close {
    position: absolute;
    width: 27px;
    height: 27px;
    background: #505759;
    border-radius: 50%;
    right: 15px;
    top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shop._single .shop__common {
    display: none;
}

.shop__sales-item  {
    width: 100%;
}

.shop__sales-item .button {
    width: 100%;
}

.shop__sales-item-content {
    display: none;
}

@media screen and (min-width: 1023px) {

    .page._inner._shop .page__header-back {
        position: static;
    }

    .page._inner._shop .page__header {
        top: 160px;
        left: 120px;
    }

    .page._inner._shop .page__wrap {
        padding-top: 160px;
        padding-bottom: 24px;
        margin: 0 auto;
        width: 100%;
        max-width: 636px;
        padding-left: 24px;
        padding-right: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .shop._single .shop__preview {
        position: relative;
        width: 282px;
        height: 282px;
        border-radius: 8px;
        min-width: 282px;
    }

    .page._inner._shop .page__footer {
        position: static;
        border: none;
        padding: 0;
    }
    
    .page._inner._shop .page__footer {
        flex-direction: row;
        gap: 24px;
    }

    
    .page._inner._shop .page__footer .button {
        max-width: 282px;
    }

    .shop._single .shop__hero {
        display: flex;
        gap: 24px;
    }

    .shop._single .shop__common {
        display: block;
    }

    .shop._single .shop__logo {
        display: none;
    }

    .shop._single .shop__title {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 8px;
    }

    .shop._single .shop__description {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .shop._single .shop__content .shop__title,
    .shop._single .shop__content .shop__description {
        display: none;
    }

    .shop__promocode {
        
    }

    .shop__sales-item {
        border-radius: 8px;
        background: #F1F2F3;
        padding: 0 12px 0;
    }


    .shop__sales-item._active {
        padding-bottom: 40px;
    }

    .shop__sales-item .promocode {
        background: none;
        padding: 0;
    }

    .shop__sales-item .promocode__footer {
        background: none;
        flex-direction: row;
    }

    .shop__sales-item .promocode__footer .button._default {
        max-width: 282px;
    }

    .shop__sales-item .promocode__footer .button._default._gray {
        background: #000;
        color: white !important;
    }

    .shop__sales-item .promocode__footer .button._default + .button._default._gray {
        /* background: white; */
        background: #ddd;
        color: #000 !important;
    }

    .shop__sales-item .button._sale {
        padding: 12px 0;
        width: 100%;
        background: none;
        position: relative;
    }

    .shop__sales-item .button._sale .button__title {
        margin-bottom: 0;
    }

    .shop__sales-item .button._sale:before {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        right: 12px;
        top: 15px;
        background: url('../../assets/img/icon-chevron.svg') no-repeat center center;
        background-size: 20px 20px;
        transform: rotate(180deg);
    }

    .shop__sales-item._active .button._sale:before  {
        transform: rotate(0deg);
    }

    .shop__sales-item-content {
        padding-top: 40px;
        padding-bottom: 40px;
        padding-bottom: 0px;
        display: none;
    }

    .shop__sales-item._active .shop__sales-item-content {
        display: block;
    }

    .shop__sales-item .alpha {
        background: none;
        color: #333;
        padding: 0;
    }

    .shop__sales-item .alpha__buttons {
        flex-direction: row;
    }

    .shop__sales-item .alpha * {
        color: inherit;
    }

    .shop._single .shop__content {
        background: none;
        border-radius: 0;
        margin: 24px 0;
        padding: 0;
    }

}