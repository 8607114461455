.results._category {
    display: flex;
    flex-direction: column;
    gap: 28px;
}

.results._category .category._default .category__list._vertical {
    gap: 0;
    margin: -10px -6px;
}

.results._category .category__list._vertical .category__list-item {
    width: 50%;
    padding: 10px 6px;
}

.results._category .post._shop {
    width: 100%;
}

.results._category .post._shop .post__preview {
    height: 162px;
}

@media screen and (min-width: 1023px) {

    .results__category._sales {
        display: none;
    }

    .results._category .category._default .category__list._vertical {
        margin: 0;
        gap: 36px 16px;
    }

    .results._category .category__list._vertical .category__list-item {
        width: 166px;
        padding: 0;
    }

}