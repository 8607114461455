.modal._category .modal__wrap {
    padding-left: 0;
    padding-right: 0;
}

.modal._category .modal__header {
    padding-left: 20px;
    padding-right: 20px;
}

.page._inner._category .page__wrap {
    padding-top: 66px;
}

.page._inner._category .page__header {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    padding: 24px 20px;
    background: #fff;
    margin-bottom: 0;
    z-index: 10;
}

@media screen and (min-width: 1023px) {

    .page._inner._category .page__wrap {
        padding-top: 24px;
    }

    .page._inner._category .page__header {
        padding: 0;
        position: relative;
        margin-bottom: 18px;
    }

    .page._inner._category .category._default .category__title {
        font-family: 'Styrene B LC';
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 114.286% */
        padding-left: 32px;
    }

    .page._inner._category .category._default .category__header {
        margin-bottom: 30px;
    }

}